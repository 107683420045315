<template>
    <!-- <div class="bg"> -->
        <div class="main">
            <!-- <div class="recharge" @click="recharge"></div> -->
            <div class="logo"></div>
            <div class="title1">有一个人在世界的某一端与你通话</div>
            <div class="title2">期待与你不期而遇</div>
            <div class="imgs">
                <img style="width: 250px" src="../../assets/dwud/1.jpg">
                <img style="width: 250px" src="../../assets/dwud/2.jpg">
                <img style="width: 250px" src="../../assets/dwud/3.jpg">
                <img style="width: 250px" src="../../assets/dwud/4.jpg">
            </div>
            <div class="download" @click="download">点击下载</div>
            <div class="title3">赣州花皮猪科技有限公司</div>
            <div class="title4">联系邮箱：huapizhu2021@163.com</div>
            <div class="title4">
                <a href="https://beian.miit.gov.cn/">赣ICP备2021001091号-1</a>
            </div>
        </div>
    <!-- </div> -->
</template>

<script>
export default {
    data() {
        return {}
    },
    methods: {
        download() {
            var link = document.createElement('a');
            link.href='https://huapz.oss-cn-shenzhen.aliyuncs.com/apk/com.huapizhu.haitang_1.0.0.apk';
            link.rel = 'external'
            link.click();
        },
        recharge() {
            this.$router.push({
                path: '/wechatRecharge',
                query: this.$route.query,
            })
        },
    },
}
</script>

<style scoped>
.bg {
    display: flex;
    width: 375px;
    height: 812px;
    background: url("../../assets/mobileWww/bg.png");
    background-repeat: no-repeat ;
    background-position: center;
    background-size: 100% ;
}

.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: gray;
    /* width: 375px; */
}

.recharge {
    width: 116px;
    height: 38px;
    /* border: 1px solid #FFFFFF; */
    border-radius: 19px;

    margin-top: 50px;
    margin-right: -175px;

    background: url("../../assets/mobileWww/recharge.png");
    background-repeat: no-repeat ;
    background-position: center;
    background-size: 100% ;
}

.logo {
    width: 63px;
    height: 102px;
    margin-top: 10px;
    /* margin-left: 153px; */

    background: url('../../assets/mobileWww/logo.png');
    background-repeat: no-repeat ;
    background-position: center;
    background-size: 100% ;
}

.title1 {
    margin-top: 20px;
    height: 17px;
    font-size: 17px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 40px;
}

.title2 {
    margin-top: 31px;
    height: 33px;
    font-size: 33px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 40px;
}

.imgs {
    width: 780px;
    margin-top: 50px;
    /* background: red; */
    display: flex;
    justify-content: space-around;
}

.download {
    width: 209px;
    height: 54px;
    margin-top: 56px;
    background: linear-gradient(-90deg, #FD4391 0%, #FE1C6D 100%);
    border-radius: 27px;

    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 19px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 25px;
}

.title3 {
    margin-top: 81px;
    height: 12px;
    font-size: 11px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 51px;
}

.title4 {
    margin-top: 18px;
    height: 14px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 40px;
}

</style>
